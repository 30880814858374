<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="observable section container-wrap overflow-hidden relative" :class="[backgroundMedia ? 'flex min-h-screen' : '']" :style="{ background: !backgroundTransition ? sectionBackgroundColor.hex : 'transparent' }">
    <div class="section_background absolute top-0 bottom-0 left-0 right-0 w-100 h-100">
      <SharedVideo v-if="backgroundMedia" :url="backgroundMedia.url" />
    </div>
    <div class="container-wrap relative section_card grid grid-cols-1 gap-0" :class="[isVertical ? 'md:grid-cols-2': 'md:grid-cols-12', (fullBleedImage || graphicImage) ? '' : 'md:pt-24', contentBlend ? 'blendCard':'']" :style="{ background: hex }">
      <div v-if="image" class="md:col-span-6 grid grid-cols-1 md:grid-cols-12 image_container overflow-hidden flex justify-center" :class="[verticalSectionPadding, imageOrder, graphicImage ? 'md:p-[120px]' : '']">
        <SharedVideo v-if="isVideo" class="w-full" :class="[isVertical ? '' : (fullBleedImage || graphicImage) ? '' : imageStart, isVertical ? 'md:col-span-6 md:col-start-4' : (fullBleedImage || graphicImage) ? 'md:col-span-12' : 'md:col-span-10']" :url="image.url" />
        <div v-else class="w-full square-box" :class="[isVertical ? '' : (fullBleedImage || graphicImage) ? '' : imageStart, isVertical ? 'md:col-span-6 md:col-start-4' : (fullBleedImage || graphicImage) ? 'md:col-span-12 h-52' : 'md:col-span-10 h-52', (graphicImage || isVertical) ? 'bg-contain bg-no-repeat' : 'bg-cover']" :style="{ backgroundImage: 'url(' + useImageOptimizer(image.url) + ')' }" />
      </div>

      <div class="md:col-span-6 grid grid-cols-6 content_container" :class="[image ? '' : 'md:col-start-4']">
        <div class="flex flex-col py-16 xl:py-20 col-span-6 md:px-0" :class="[backgroundMedia ? 'justify-top' : 'justify-center', (contentAlign !== 'center' || list) ? 'px-8' : 'px-12',fullBleedImage ? 'md:col-span-4 md:col-start-2' : 'md:col-span-4 md:col-start-2', alignContent]">
          <h2 class="font-druk uppercase leading-none" :class="[list ? 'text-display-12 lg:text-display-14' : ' text-display-14 xl:text-display-20']" :style="{ color: title1Color.hex }">
            {{ getTitle1 }}
          </h2>
          <h2 v-if="title2" class="font-druk uppercase leading-none" :class="[list ? 'text-display-12 lg:text-display-14' : ' text-display-14 xl:text-display-20']" :style="{ color: title2Color.hex }">
            {{ title2 }}
          </h2>
          <div class="standard-content-text mt-6 font-light leading-5" :class="[list ? 'text-smaller lg:text-display-5 font-light leading-[26px]' : 'text-smallest lg:text-smaller', alignContent]" :style="{ color: bodyColor.hex }" v-html="getBody" />
          <div v-if="ctaType !== 'none'" class="action_holder flex" :class="[alignContent]">
            <SharedArrowButton width="wrap" class="mt-8 mb-16" :background="ctaBackgroundColor.hex" :color="ctaTextColor.hex" :border="ctaOutline" :text="ctaText" @click="ctaClicked()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const route = useRoute()
const countriesStore = useCountriesStore()

const props = defineProps({
  title1: {
    type: String,
    default: ''
  },
  title1Color: {
    type: Object,
    default: () => {
      return {
        hex: '#FF3846'
      }
    }
  },
  title2: {
    type: String,
    default: null
  },
  title2Color: {
    type: Object,
    default: () => {
      return {
        hex: '#FF3846'
      }
    }
  },
  body: {
    type: String,
    default: ''
  },
  bodyColor: {
    type: Object,
    default: () => {
      return {
        hex: 'black'
      }
    }
  },
  ctaText: {
    type: String,
    default: 'Learn more'
  },
  ctaBackgroundColor: {
    type: Object,
    default: () => {
      return {
        hex: 'white'
      }
    }
  },
  ctaTextColor: {
    type: Object,
    default: () => {
      return {
        hex: 'black'
      }
    }
  },
  ctaType: {
    type: String,
    default: 'none'
  },
  ctaUrl: {
    type: String,
    default: null
  },
  ctaOutline: {
    type: Boolean,
    default: false
  },
  sectionBackgroundColor: {
    type: Object,
    default: () => {
      return {
        hex: '#F8B8CC'
      }
    }
  },
  imagePosition: {
    type: String,
    default: 'left',
    enum: ['left', 'right', 'top', 'bottom']
  },
  imagePositionMobile: {
    type: String,
    default: 'bottom',
    enum: ['top', 'bottom']
  },
  image: {
    type: Object,
    default: null
  },
  contentAlign: {
    type: String,
    default: 'center'
  },
  backgroundTransition: {
    type: Boolean,
    default: false
  },
  list: {
    type: Boolean,
    default: false
  },
  fullBleedImage: {
    type: Boolean,
    default: false
  },
  graphicImage: {
    type: Boolean,
    default: false
  },
  backgroundMedia: {
    type: Object,
    default: null
  },
  contentBlend: {
    type: Boolean,
    default: false
  }
})

const isVideo = ref(false)

if (props.image?.url?.includes('mp4')) {
  isVideo.value = true
}

const getTitle1 = computed(() => {
  if (props.title1.indexOf(/{{country}}/g) && route.params.country) {
    return props.title1.replace(/{{country}}/g, route.params.country)
  }
  return props.title1
})
const getBody = computed(() => {
  if (props.title1.indexOf(/{{country}}/g) && route.params.country) {
    return props.body.replace(/{{country}}/g, '<span class="capitalize">' + route.params.country + '</span>')
  }
  return props.body
})

const imageOrder = computed(() => {
  if (props.image) {
    if (props.imagePositionMobile === 'top' && props.imagePosition === 'right') {
      return 'order-first md:order-last'
    }
    if (props.imagePositionMobile === 'bottom' && props.imagePosition === 'right') {
      return 'order-last'
    }
    if (props.imagePositionMobile === 'top' && props.imagePosition === 'left') {
      return 'order-first'
    }
    if (props.imagePositionMobile === 'bottom' && props.imagePosition === 'left') {
      return 'order-last md:order-first'
    }

    if (props.imagePositionMobile === 'top' && props.imagePosition === 'bottom') {
      return 'order-first md:order-last'
    }
    if (props.imagePositionMobile === 'bottom' && props.imagePosition === 'bottom') {
      return 'order-last'
    }
    if (props.imagePositionMobile === 'top' && props.imagePosition === 'top') {
      return 'order-first'
    }
    if (props.imagePositionMobile === 'bottom' && props.imagePosition === 'top') {
      return 'order-last md:order-first'
    }
  }
})

const isVertical = computed(() => {
  return props.imagePosition === 'top' || props.imagePosition === 'bottom'
})

const verticalSectionPadding = computed(() => {
  if (props.imagePosition === 'top') {
    return 'pt-20'
  }
  if (props.imagePosition === 'bottom') {
    return 'pb-20'
  }
  return ''
})

const alignContent = computed(() => {
  if (props.list && !isVertical.value) {
    return 'text-left items-start'
  }
  let justify = props.contentAlign
  props.contentAlign === 'left' ? justify = 'start' : props.contentAlign === 'right' ? justify = 'end' : justify = 'center'
  const textAlign = isVertical ? 'text-center' : `text-${props.contentAlign}`
  const contentAlign = isVertical ? 'items-center' : `items-${justify}`
  return `${textAlign} ${contentAlign}`
})

const imageStart = computed(() => {
  if (props.image && props.imagePosition === 'left') {
    return 'md:col-start-2'
  }
})

const emit = defineEmits(['ctaClicked'])

const ctaClicked = () => {
  emit('ctaClicked', {
    type: props.ctaType,
    url: props.ctaUrl
  })
}
</script>
<style scoped>
.section_background {
  transform: rotate(180deg);
}
.square-box{
  padding-bottom: 100%;
  background-position: center;
}
.rect-box{
  padding-bottom: 50%;
  background-position: top;
}
.standard-content-text :deep(ul), .standard-content-text :deep(ol){
  list-style: none;
  position: relative;
  margin-left: 0;
}
.standard-content-text :deep(ol){
  counter-reset: standard-content-counter;
}
.standard-content-text :deep(ul li), .standard-content-text :deep(ol li){
  position: relative;
  padding-top: 1rem;
  padding-left: 1.5em;
  line-height: 1.3;
}

.standard-content-text :deep(ol li){
  counter-increment: standard-content-counter;
  text-align: left;
}

.standard-content-text :deep(ul li)::before, .standard-content-text :deep(ol li)::before {
  position: absolute;
  left: 0;
  font-size: 18px;
}
.standard-content-text :deep(ul li)::before {
  content: "\e00c";
  font-family: majority-dsm;
}

.standard-content-text :deep(ol li)::before {
  content: counter(standard-content-counter) ". ";
}
.blendCard {
  color: black;
  mix-blend-mode: difference;
  filter: invert(1) grayscale(1);
}
.backgroundVideo {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>
